<template>
  <section>
    <v-container>
      <v-row>
        <v-col lg="9" md="8">
          <div class="my-7">
            <span class="gothic-3">Scheduled Trips</span>
          </div>
          <v-card v-for="(trip, index) in trips" :key="index" class="rounded-lg mb-10" elevation="0">
            <v-carousel hide-delimiters :cycle="true" :show-arrows="false" class="mx-auto mt-4" style="height: 400px;">
              <section  v-for="(image, index) in trip.images" :key="index">
                <v-carousel-item :src="image.url"></v-carousel-item>
              </section>
              <v-overlay :absolute="true" :opacity="0.8" z-index="2">
                <div class="pa-10 text-center">
                  <div class="goBold-4 pb-4">
                    {{ trip.name }}
                  </div>
                  <div class="gothic-1">
                    <div v-if="trip.dates.length === 1">
                      <div>{{ trip.dates[0].nights }} night trip</div>
                      <div class="mt-2">{{ $moment(trip.dates[0].start).format('LL') }} to {{ $moment(trip.dates[0].end).format('LL') }}</div>
                    </div>
                    <div v-else>
                      Multiple dates available
                    </div>
                  </div>
                  <div class="friendly-1 mt-6 font-weight-bold" v-if="trip.isFilled">
                    <span class="ml-2">Fully booked!</span>
                  </div>
                  <div class="mt-6">
                    <outlined-white-button @click="goToTrip(trip.id)">Learn More</outlined-white-button>
                  </div>
                </div>
              </v-overlay>
            </v-carousel>
          </v-card>
        </v-col>
        <v-col lg="3" md="4">
          <div class="stickyTripsPanel mb-10">
            <v-card v-if="nextThreeTrips.length >= 1" class="rounded-lg mb-5 hidden-sm-and-down" elevation="0">
              <v-card-title>
                <span class="gothic-2">Upcoming Trips</span>
              </v-card-title>
              <v-card-text>
                <div v-for="(trip, index) in nextThreeTrips" :key="index">
                  <v-divider></v-divider>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="goBold-1">
                          {{ trip.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                        <span v-if="trip.dates.length === 1">
                          <div>{{ $moment(trip.dates[0].start).format('ll') }} to {{ $moment(trip.dates[0].end).format('ll') }}</div>
                        </span>
                          <span v-else>
                          Multiple dates available
                        </span>
                        </v-list-item-subtitle>
                        <v-list-item-action-text>
                          <p v-snip="{ lines: 2, mode: 'js', midWord: false }">
                            {{ trip.subtitle }}
                          </p>
                        </v-list-item-action-text>
                        <v-btn block color="primary" @click="goToTrip(trip.id)">
                          Learn More
                        </v-btn>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </v-card-text>
            </v-card>
<!--            <div class="ma-8">-->
<!--              <v-img class="rounded-lg" :aspect-ratio="2" src="https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/companies/cyborg-pirate.jpg">-->
<!--                <template v-slot:placeholder>-->
<!--                  <v-row class="fill-height ma-0" align="center" justify="center">-->
<!--                    <v-progress-circular indeterminate></v-progress-circular>-->
<!--                  </v-row>-->
<!--                </template>-->
<!--                <v-overlay :absolute="true" :opacity="0.4" z-index="4" class="cyborgPirate insetShadow">-->
<!--                  <div class="text-center" style="position: relative; width: 100%; height: 100%;">-->
<!--                    <outlined-white-button @click="doOpenExternalUrl('https://cyborgpirate.com/dive-charter-schedule')">-->
<!--                      <font-awesome-icon :icon="['fas', 'ship']" class="mr-2"/>-->
<!--                      <span class="goBold-1">AZ boat dives</span>-->
<!--                    </outlined-white-button>-->
<!--                  </div>-->
<!--                </v-overlay>-->
<!--              </v-img>-->
<!--            </div>-->
          </div>
        </v-col>
      </v-row>
      <div v-if="!isLoading && trips.length === 0" class="text-center expanded">
        <v-btn outlined width="400" color="primary" class="mt-2" @click="doCallBusiness">
          <font-awesome-icon size="1x" :icon="['fa', 'phone']"></font-awesome-icon>
          <span class="gothic-1 ml-2">Call for available trips</span>
        </v-btn>
      </div>
    </v-container>
    <wave-effect></wave-effect>
  </section>
</template>

<script>

import OutlinedWhiteButton from '@/components/outlinedWhiteButton.vue'
import WaveEffect from '@/components/waveEffect.vue'

export default {
  name: 'Trips',
  components: { WaveEffect, OutlinedWhiteButton },
  data: () => ({
    isLoading: false,
    trips: []
  }),
  computed: {
    nextThreeTrips () {
      return this.trips.slice(0, 3)
    }
  },
  methods: {
    doLoad () {
      this.isLoading = true

      this.$services.trip.doGetAllActive().then(trips => {
        this.trips = trips
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    goToTrip (id) {
      this.$router.push({ name: 'view-trip', params: { id: id } })
    }
  },
  mounted () {
    this.doLoad()
  },
  created () {
    this.doSetDocumentTitle('Scheduled Trips', true)
  }
}
</script>

<style scoped>
  .stickyTripsPanel {
    position: -webkit-sticky;
    position: sticky;
    top: 92px;
  }

  .insetShadow {
    -webkit-box-shadow: inset 0px -50px 100px -20px rgba(0,0,0,1);
    -moz-box-shadow: inset 0px -50px 100px -20px rgba(0,0,0,1);
    box-shadow: inset 0px -50px 100px -20px rgba(0,0,0,1);
  }
</style>
